import type { DefaultMantineColor } from '@mantine/core';
import type { NotificationData } from '@mantine/notifications';
import { notifications } from '@mantine/notifications';

const displayToast =
  (color: DefaultMantineColor) =>
  (message: NotificationData['message'], title?: NotificationData['title']) => {
    notifications.show({
      withBorder: true,
      color,
      message: title ? message : undefined,
      title: title ? title : message,
      radius: 'md',
      p: 'lg',
    });
  };

export const toast = {
  warning: displayToast('yellow'),
  error: displayToast('red'),
  success: displayToast('green'),
};

export const defaultToastNotification = (e?: unknown) => {
  if (process.env.NODE_ENV === 'development' && e) {
    toast.error(`${e}`);
  } else {
    toast.error('Something went wrong.');
  }
};
