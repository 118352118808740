import Router from 'next/router';

type SortOrder = 'descend' | 'ascend' | null;

export const flattenParam = (value: string | string[] | undefined) => {
  if (Array.isArray(value)) {
    return value[0];
  }
  return value;
};

export const getUrlParam = (param: string) => {
  const { query } = Router;
  return flattenParam(query[param]);
};

let timeout: number;
let newQueries: {
  [x: string]: string | string[] | undefined;
}[] = [];
// Helpers for manipulating current URL query params
export const replaceUrlParams = (params: {
  [key: string]: string | string[] | undefined | null;
}) => {
  // Router might be "undefined" when this runs in SSR
  try {
    // eslint-disable-next-line @typescript-eslint/no-unused-vars
    const { query: test } = Router;
  } catch (e) {
    return;
  }
  const { query, pathname } = Router;
  const newQuery = { ...query };
  Object.keys(params).forEach((k) => {
    let value = params[k];
    if (Array.isArray(value)) {
      // remove duplicates
      value = Array.from(new Set(value));
      if (value.length === 0) {
        value = undefined;
      } else {
        value = value.join(',');
      }
    }
    newQuery[k] = value || undefined;
  });
  // Batch updates to URL to avoid race conditions and too many refreshes
  newQueries.push(newQuery);
  clearTimeout(timeout);
  timeout = window.setTimeout(() => {
    const mergedNewQuery = Object.assign({}, ...newQueries);
    // Remove undefined values so they don't pollute the URL
    Object.keys(mergedNewQuery).forEach((k) => {
      const value = mergedNewQuery[k];
      if (value === undefined || value === null) {
        delete mergedNewQuery[k];
      }
    });

    void Router.replace(
      {
        pathname,
        query: mergedNewQuery,
      },
      undefined,
      { shallow: true },
    );
    newQueries = [];
  }, 100);
};

export const replaceUrlParam = (
  name: string,
  value?: string | string[] | null,
) => replaceUrlParams({ [name]: value });

export const parseUrlSort = (param: string | string[] | undefined) => {
  const flattened = flattenParam(param);
  let order: SortOrder | undefined;
  let columnKey: string | string[] | undefined;
  if (param) {
    order = flattened?.includes('-') ? 'descend' : 'ascend';
    columnKey = flattened?.replace(/^-/, '') || '';
  } else {
    order = undefined;
    columnKey = undefined;
  }
  return { order, columnKey };
};

// This can be used to check if any url query params are active
export const isObjectEmpty = (obj: {
  [key: string]: string | string[] | undefined;
}) => {
  let empty = true;
  Object.keys(obj).forEach((key) => {
    const value = obj[key];
    if (!value) {
      return;
    }
    if (value.length > 0) {
      empty = false;
      return;
    }
  });
  return empty;
};
