import { createContext, useContext, useEffect, useState } from 'react';
import { useGetCurrentOrg } from 'hooks/user/use-get-current-org';

import { isFeatureFlagEnabled } from 'utils/feature-flags';
import { logger } from 'utils/logger';

export interface Flags {
  [flag: string]: { enabled: '0' | '1'; orgs?: Record<string, '0' | '1'> };
}

const FeatureFlagsContext = createContext<Flags>({});

type FeatureFlagsProps = React.PropsWithChildren;

export const FeatureFlagsProvider = ({ children }: FeatureFlagsProps) => {
  const [flags, setFlags] = useState({});
  useEffect(() => {
    const fetchFlags = async () => {
      const flags = await (await fetch('/api/feature-flags')).json();
      setFlags(flags || {});
    };
    fetchFlags().catch((e) => {
      logger.error("Couldn't fetch feature flags", e);
    });
  }, []);
  return (
    <FeatureFlagsContext.Provider value={flags}>
      {children}
    </FeatureFlagsContext.Provider>
  );
};

export const useIsFeatEnabled = (feat: string) => {
  const flags = useContext(FeatureFlagsContext);
  const org = useGetCurrentOrg();
  return isFeatureFlagEnabled(flags, feat, org.id);
};
