import React, { createContext, useEffect, useState } from 'react';
import { IntlProvider } from 'react-intl';
import { useDidUpdate } from '@mantine/hooks';

import es from '../../../lang/translated/es.json';

export type Lang = 'en' | 'es';

type LangContext = {
  lang: Lang;
  setLang: (lang: Lang) => void;
};

export const LangContext = createContext<LangContext | undefined>(undefined);

export const LangProvider = ({ children }: React.PropsWithChildren) => {
  const [lang, setLang] = useState<Lang>('en');

  useEffect(() => {
    const storedLang = localStorage.getItem('lang');
    if (storedLang && ['en', 'es'].includes(storedLang)) {
      setLang(storedLang as Lang);
    }
  }, []);

  useDidUpdate(() => {
    localStorage.setItem('lang', lang);
  }, [lang]);

  return (
    <LangContext.Provider value={{ lang, setLang }}>
      <IntlProvider
        locale={lang}
        messages={lang === 'es' ? es : undefined}
        defaultLocale="en"
      >
        {children}
      </IntlProvider>
    </LangContext.Provider>
  );
};
