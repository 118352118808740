import { useEffect } from 'react';
import type { NotificationType } from '@prisma/client';
import { Realtime } from 'ably';
import { useSession } from 'next-auth/react';

import { messageHandlers } from 'components/messages/message-handlers';

export const MessageCenter = () => {
  const session = useSession();
  const organizationId = session.data?.user?.organizationId;

  useEffect(() => {
    if (organizationId) {
      const ablyClient = new Realtime({
        authUrl: '/api/ably-auth',
      });
      const channel = ablyClient.channels.get(`organization:${organizationId}`);
      void channel.subscribe((message) => {
        const handler = messageHandlers[message.name as NotificationType];
        handler?.(message.data);
      });
      return () => {
        ablyClient.close();
      };
    }
  }, [organizationId]);

  return <div></div>;
};
