import type { Flags } from 'components/utils/FeatureFlags';

export const isFeatureFlagEnabled = (
  flags: Flags | undefined,
  feat: string,
  orgId?: string | null,
) => {
  const flagInfo = flags?.[feat];
  if (flagInfo) {
    if (orgId && flagInfo.orgs && flagInfo.orgs[orgId]) {
      return flagInfo.orgs[orgId] === '1';
    } else {
      return flagInfo.enabled === '1';
    }
  }
  return false;
};
