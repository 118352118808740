import { useEffect } from 'react';

export const useReloadOnTimeout = (seconds: number) => {
  useEffect(() => {
    const timer = setTimeout(() => {
      window.location.reload();
    }, seconds * 1000);
    return () => clearTimeout(timer);
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, []);
};
